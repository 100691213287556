* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  font-size: 62.5%;
  --FH: 3rem;
  --FSH: 2.5rem;
  --FB: 2rem;
}
body {
  background-color: crimson;
  background-image: url("./assets/hell-background.jpg");
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  color: rgb(15, 9, 1);
  font-size: var(--FB);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: auto;
  cursor: url("./assets/fork2.png"), pointer;
  text-transform: uppercase;
}
h1 {
  font-size: var(--FH);
  text-shadow: 1px 2px 3px snow;
}
h2 {
  font-size: var(--FSH);
  text-shadow: 0 0 2px snow;
}
a,
button {
  text-align: center;
  padding: 10px 15px;
  font-size: var(--FSH);
  width: clamp(50px, 100%, 400px);
  text-transform: inherit;
}
a:any-link,
button {
  color: inherit;
  text-decoration: none;
  background-color: ivory;
  outline: transparent;
  border: none;
}
a:hover,
button:hover {
  cursor: url("./assets/fork.png"), pointer;
}
.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  --CW: clamp(200px, 100%, 768px);
  --CH: 100%;
  --GAP: 20px;
}
.container {
  position: relative;
  background-color: lightslategrey;
  width: var(--CW);
  height: 100%;
  padding: var(--GAP);
}
.container::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: aqua;
  background-image: url("./assets/blue-flames.webp");
  background-size: cover;
  width: calc(var(--CW) + 10px);
  height: calc(var(--CH) + 10px);
  z-index: -1;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  height: 100%;
}
.content,
.symbol-page {
  width: var(--CW);
  margin-inline: auto;
  text-align: center;
}
.msg-wrapper {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: var(--GAP);
  width: 100%;
}
.navs {
  display: flex;
  flex-direction: column;
  gap: var(--GAP);
  width: 100%;
  align-items: center;
}
.info-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: calc(var(--GAP) * 3);
}
/* .instruction .instruc-list {
  gap: calc(var(--GAP) + 10px);
} */
.symbol-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--GAP);
}
.symbol-page h1 {
  text-decoration: underline;
  text-underline-offset: calc(var(--GAP) / 3);
}
.symbols-wrapper {
  overflow-y: scroll;
}
.symbols {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--GAP);
}
.symbol-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--GAP);
}
.symbol-box img {
  width: 48px;
  height: auto;
}
.symbol-navs {
  display: flex;
  width: 100%;
  gap: var(--GAP);
}
.result {
  display: flex;
  flex-direction: column;
  gap: var(--GAP);
  align-items: center;
  justify-content: center;
  animation: show 10s forwards ease;
}
.result h3 {
  font-size: inherit;
  font-weight: normal;
}
.result img {
  width: 128px;
  height: auto;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 375px) {
  .App {
    padding: 10px;
  }
  .symbol-page h1 {
    text-underline-offset: calc(var(--GAP) / 2);
  }
}
@media screen and (min-width: 768px) {
  body {
    font-size: calc(var(--FB) + 0.5rem);
  }
  h1 {
    font-size: calc(var(--FH) + 0.5rem);
  }
  h2 {
    font-size: calc(var(--FSH) + 0.5rem);
  }
  a,
  button {
    font-size: calc(var(--FSH) + 0.5rem);
    padding: 15px 30px;
  }
  .symbols {
    grid-template-columns: repeat(5, 1fr);
  }
  .symbol-box img {
    width: 64px;
    height: auto;
  }
  .result img {
    width: 175px;
  }
}
